import React from 'react';
import { Box, Burger, Button, Center, Divider, Drawer, Group, HoverCard, Image, List, rem, ScrollArea, SimpleGrid, Text } from '@mantine/core';
import Link from 'next/link';
import { useDisclosure } from '@mantine/hooks';
import { ChevronDown } from 'tabler-icons-react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import * as Redux from 'react-redux';

// import { useIsMobile } from '../lib/useIsMobile'
import { VersionContext } from '../contexts/VersionContext';
import * as Selectors from '../redux/selectors';
import * as Actions from 'redux/actions';
import AccountModal from './AccountModal';
import CountryBadge from './CountryBadge';
import { sluggify } from '../lib/helper';
import * as Consts from 'consts';
import * as Types from 'declarations';
const BrandTitle = () => {
  // const isMobile = useIsMobile()
  const version = React.useContext(VersionContext)?.version;
  return <Link href="/" legacyBehavior data-sentry-element="Link" data-sentry-component="BrandTitle" data-sentry-source-file="Header.tsx">
			<a className="brand-title">
				<Image src={version === Types.VERSION.PEAKS ? '/branding/guided-peaks.png' : '/branding/guided-trekking.png'} alt={version === Types.VERSION.PEAKS ? 'Guided Peaks' : 'Guided Trekking'} title={version === Types.VERSION.PEAKS ? 'Guided Peaks' : 'Guided Trekking'} height={'40px'} width={version === Types.VERSION.PEAKS ? '121px' : '133px'} data-sentry-element="Image" data-sentry-source-file="Header.tsx" />
			</a>
		</Link>;
};
const HeaderMegaMenu = () => {
  const router = useRouter();
  const [drawerOpened, {
    toggle: toggleDrawer,
    close: closeDrawer
  }] = useDisclosure(false);
  const dispatch = Redux.useDispatch();
  React.useEffect(() => {
    // Check if the URL has the specific hash
    if (router.asPath.includes('#open-login-guide')) {
      dispatch(Actions.openLogin());
    }
  }, [router.asPath, dispatch]);
  const authedUser = useSelector(Selectors.authedUser);
  const isAdmin = useSelector(Selectors.isUserAdmin);
  const version = React.useContext(VersionContext).version;
  const isPeaks = version === 'PEAKS';
  const menuItemsRaw: {
    label: string;
    href: string;
    isActive: boolean;
    children?: {
      continentName: string;
      blurb: string;
      climbBlurb: string;
      countries: {
        label: string;
        href: string;
        isoCode: string;
      }[];
    }[][];
  }[] = [{
    label: isPeaks ? 'Expeditions' : 'Treks',
    href: '/expeditions',
    isActive: ['/expeditions', `/[routeType]/[continent]/[countries]/[slug]`, `/[routeType]/[continent]/[countries]/[slug]/[...guidesSlug]`].some(route => {
      return router.pathname === route;
    })
  }, ...(isPeaks ? [{
    label: 'Guides',
    href: '/guides',
    isActive: router.pathname.startsWith('/guides')
  }, {
    label: 'Articles',
    href: '/articles',
    isActive: router.pathname.startsWith('/articles')
  }, {
    label: 'Countries',
    href: '/countries',
    isActive: router.pathname.startsWith('/countries') || router.pathname === `/[routeType]/[continent]/[countries]` || router.pathname === `/[routeType]/[continent]`,
    children: Consts.regions.map(region => region.map(continent => ({
      continentName: continent.continent,
      blurb: continent.blurb,
      climbBlurb: continent.climbBlurb,
      countries: continent.countries
    })))
  }, {
    label: 'About',
    href: '/about',
    isActive: router.pathname === '/about'
  }] : [])];
  const closeMenuOnClick = () => {
    closeDrawer();
  };
  const countryMenuMobile = Consts.regions.map((region, idxRegion) => <Box ml="xl" mt="lg" key={idxRegion}>
			{region.map((continent, idxContinent) => <div key={idxContinent}>
					<Link href={`/climbing/${sluggify(continent.continent)}`} onClick={closeMenuOnClick}>
						<Text size="sm" fw={600}
        // m="sm"
        mb="sm" className={classNames({
          'header-link': true,
          'active-link': ['/countries', `/[routeType]/[continent]`, `/[routeType]/[continent]/[countries]`].some(route => {
            return router.pathname === route;
          })
        })}>
							{continent.continent}
						</Text>
					</Link>
					<div style={{
        display: 'block'
      }}>
						{continent.countries.map((country, idxCountry) => <Link href={country.href} className="header-link" key={idxCountry} style={{
          margin: '0 8px 8px 0',
          display: 'inline-block',
          width: 'auto'
        }} onClick={closeMenuOnClick}>
								<CountryBadge isoCode={country.isoCode} />
							</Link>)}
					</div>
				</div>)}
		</Box>);
  const headerTextSize = 'md';
  return <div id="header2" data-sentry-component="HeaderMegaMenu" data-sentry-source-file="Header.tsx">
			<header>
				<Group style={{
        justifyContent: 'space-between',
        // zIndex: 10005, // remove as conflicted with modal
        position: 'relative',
        backgroundColor: '#fff'
      }} h="100%" id="brand" data-sentry-element="Group" data-sentry-source-file="Header.tsx">
					<BrandTitle data-sentry-element="BrandTitle" data-sentry-source-file="Header.tsx" />

					<div className="mobile-hide">
						<Group data-sentry-element="Group" data-sentry-source-file="Header.tsx">
							{/* <> */}
							{menuItemsRaw.map((menuItem, idxMenuItem) => {
              return !menuItem?.children ? <Link href={menuItem.href} legacyBehavior key={idxMenuItem}>
										<a className={'header-link'}>
											<Text size={headerTextSize} m="sm" fw={500} className={classNames({
                    'header-link': true,
                    'active-link': menuItem.isActive
                  })}>
												{menuItem.label}
											</Text>
										</a>
									</Link> : <HoverCard position="bottom" radius="md" shadow="md" withinPortal key={idxMenuItem}>
										<HoverCard.Target>
											<Link href={menuItem.href} className={'header-link'}>
												<Center inline>
													<Box component="span" mr={5}>
														<Text size={headerTextSize} m="sm" fw={500} className={classNames({
                          'header-link': true,
                          'active-link': router.pathname.startsWith('/countries') || router.pathname === `/[routeType]/[continent]/[countries]` || router.pathname === `/[routeType]/[continent]`
                        })}>
															{menuItem.label}
														</Text>
													</Box>
													<ChevronDown style={{
                        width: rem(16),
                        height: rem(16)
                      }} />
												</Center>
											</Link>
										</HoverCard.Target>

										<HoverCard.Dropdown style={{
                  overflow: 'hidden',
                  width: '600px'
                }}>
											<SimpleGrid cols={3} spacing={0}>
												{(menuItem?.children ?? []).map((region, idxRegion) => <div key={idxRegion}>
														{region.map((continent, idxContinent) => <div key={idxContinent}>
																<Link href={`/climbing/${sluggify(continent.continentName)}`}>
																	<Text size="sm" fw={600} m="sm" className={classNames({
                            'header-link': true,
                            'active-link': ['/countries', `/[routeType]/[continent]`, `/[routeType]/[continent]/[countries]`].some(route => {
                              return router.pathname === route;
                            })
                          })}>
																		{continent.continentName}
																	</Text>
																</Link>
																<List listStyleType="none">
																	{continent.countries.map(country => <List.Item key={country.label}>
																			<Link href={country.href} className="header-link">
																				<Text size="sm" m="xs" fw={500}>
																					<CountryBadge isoCode={country.isoCode} />
																				</Text>
																			</Link>
																		</List.Item>)}
																</List>
															</div>)}
													</div>)}
											</SimpleGrid>
										</HoverCard.Dropdown>
									</HoverCard>;
            })}
							{/* </> */}
						</Group>
					</div>

					<div className="mobile-hide">
						<Group p="xs" data-sentry-element="Group" data-sentry-source-file="Header.tsx">
							{isAdmin && <Link href="/admin" passHref>
									<Button title={`logged in as ${authedUser?.id} (${authedUser?.role})`}>
										Admin
									</Button>
								</Link>}
							<AccountModal data-sentry-element="AccountModal" data-sentry-source-file="Header.tsx" />
						</Group>
					</div>

					<Burger opened={drawerOpened} onClick={toggleDrawer} className="mobile-show" mr="sm" title="Open navigation" aria-label="Open navigation" data-sentry-element="Burger" data-sentry-source-file="Header.tsx" />
				</Group>
			</header>

			<Drawer opened={drawerOpened} onClose={closeDrawer} size="100%" padding="md"
    // title={<BrandTitle />}
    title={null} className="mobile-show" zIndex={10000} position="top" data-sentry-element="Drawer" data-sentry-source-file="Header.tsx">
				<>
					<ScrollArea h={`calc(100vh - 95px - 8px - 12px)`}
        // mx="-md"
        // p="md"
        // style={{ backgroundColor: 'beige' }}
        pt="sm" data-sentry-element="ScrollArea" data-sentry-source-file="Header.tsx">
						{menuItemsRaw.map((menuItem, idxMenuItem) => {
            return !menuItem?.children ? <Link href={menuItem.href} legacyBehavior style={{
              margin: '16px'
            }} key={idxMenuItem}
            // onClick={closeMenuOnClick}
            >
									<a className={'header-link'}>
										<Text size="lg" fw={500} mb="md" onClick={closeMenuOnClick}>
											{menuItem.label}
										</Text>
									</a>
								</Link> : <List style={{
              margin: '16px',
              marginLeft: 0
            }} key={idxMenuItem}>
									<Text size="lg" fw={500}>
										<Link href={menuItem.href} className={'header-link'} legacyBehavior style={{
                  margin: '16px',
                  marginLeft: 0
                }}>
											<span>{menuItem.label}:</span>
										</Link>
									</Text>
									{countryMenuMobile}
								</List>;
          })}
					</ScrollArea>
					<div className="header-mobile-button-group">
						<Group grow pt={'8px'} data-sentry-element="Group" data-sentry-source-file="Header.tsx">
							{isAdmin && <Link href="/admin" passHref>
									<Button fullWidth title={`logged in as ${authedUser?.id} (${authedUser?.role})`} className="header-mobile-button-group-button">
										Admin
									</Button>
								</Link>}
							<AccountModal data-sentry-element="AccountModal" data-sentry-source-file="Header.tsx" />
						</Group>
					</div>
				</>
			</Drawer>
		</div>;
};
export default HeaderMegaMenu;